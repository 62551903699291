/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.dropzone {
    display: inline-block;
    position: relative;

    &__view {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        font-size: var(--font-size-2xl);
        line-height: var(--line-height-condensed);
        color: var(--color-dark-indigo-30);

        background-color: var(--color-white-60);
        border: 2px dashed var(--color-dark-indigo-60);
        border-radius: 8px;
        box-sizing: border-box;

        opacity: 0;
        transition: 0.6s;

        &_active {
            opacity: var(--opacity-active);
            transition: 0.6s;
        }
    }
}
