@import "../../styles/common";

.dropzone__view {
  display: none;
}

.DocumentDropzone {
  width: 100%;
  height: 177px;
  border: 2px dashed rgba(0, 16, 61, 0.12);
  border-radius: 4px;

  display: flex;
  justify-content: center;

  transition: all 0.3s ease-in-out;

  @include mq(mobile) {
    border: none !important;
    width: 100%;
    height: fit-content;
    justify-content: start;
  }
}

.DocumentDropzone_active {
  border-color: rgba(0, 16, 61, 0.48);
}

.DocumentDropzone_content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(mobile) {
    align-items: flex-start;
    width: 100%;
  }
}

.DocumentDropzone_linkWrapper {
  display: flex;
  justify-content: flex-start;
}

.DocumentDropzone_separator {
  @include mq(desktop) {
    display: none;
  }

  @include mq(mobile) {
    display: inline;
    margin: 20px 13px 0 0;
    line-height: 20px;
  }
}

.DocumentDropzone_appendLink {
  margin-top: 20px;
  display: flex;

  color: #2C2D2E;
  cursor: pointer;

  &:hover {
    color: #FC2C38;
  }
}

.DocumentDropzone_appendLinkIcon {
  width: 14px;
  margin-right: 13px;
}

.DocumentDropzone_appendLinkText {
  font-size: 15px;
  line-height: 20px;
  text-decoration: underline;
}

.DocumentDropzone_hint {
  margin-top: 32px;
  max-width: 200px;
  font-size: 15px;
  line-height: 20px;
  color: #919399;
  text-align: center;

  @include mq(mobile) {
    display: none;
  }
}

.DocumentDropzone_files {
  @include mq(mobile) {
    margin-top: 16px;
    margin-left: -20px;
    width: calc(100% + 40px);
    border-top: 1px solid rgba(0, 16, 61, 0.12);
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
}

.DocumentDropzone_file {
  position: relative;
  width: 96px;
  height: 68px;
  margin-top: 32px;
  padding: 8px;
  background: rgba(0, 16, 61, 0.06);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq(mobile) {
    margin: 20px;
  }
}

.DocumentDropzone_fileIcon {
  width: 24px;
  height: 24px;
}

.DocumentDropzone_fileName {
  font-size: 11px;
  color: #2C2D2E;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DocumentDropzone_fileDelete {
  position: absolute;
  right: 0;
  top: -8px;

  width: 24px;
  height: 24px;

  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.DocumentDropzone_fileDeleteIcon {
  width: 10px;
  height: 10px;
}
