.DetailRow {
  display: flex;

  dt {
    color: #a0a0a0;
    font-weight: lighter;
    font-size: 0.9em;

    em {
      display: block;
      font-size: 8px;
      font-style: normal;
    }
  }

  dd {
    border-left: 1px solid #a0a0a0;
    margin-bottom: 0px;

    ul {
      list-style-type: none;
      padding-left: 0px;
    }
  }
}

@media (max-width: 767px) {
  .DetailRow {
    dd {
      border-left: 0px;
    }
  }
}
