@import "../../styles/common";

.DocumentPreview_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 16, 61, 0.88) !important;
}

.DocumentPreview_content {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

.DocumentPreview_close {
    position: absolute;
    width: 20px;
    height: 20px;

    @include mq(desktop) {
        right: 40px;
        top: 32px;
    }

    @include mq(mobile) {
        right: calc((10% - 20px) / 2);
        top: calc((10% - 20px) / 2);;
    }

    &:hover {
        cursor: pointer;
    }
}

.DocumentPreview_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq(desktop) {
        height: 64px;
        background-color: #394669;
    }

    @include mq(mobile) {
        height: 80px;
        justify-content: center;
        background-color: #FFFFFF;
        border-top: 1px solid rgba(0, 16, 61, 0.12);
    }

    .DocumentControls {
        margin-top: 0;

        .DocumentControls_copyButton, .button_view_action {
            background-color: white;

            &.button_hovered {
                background-color: #b4bcc2 !important;
            }
        }
    }
}

.DocumentPreview_documentLink {
    font-size: 17px;
    color: white;
    text-decoration: none;
    max-width: 420px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mq(mobile) {
        display: none;
    }

    &:hover {
        color: white;
        text-decoration: underline;
    }
}

.DocumentPreview_document {
    padding-top: 100%;
    padding-bottom: 96px;
    overflow-y: scroll;

    @include mq(desktop) {
        margin-top: calc(32px - 100%);
        width: 40%;
    }

    @include mq(mobile) {
        margin-top: calc(56px - 100%);
        width: 80%;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

.DocumentPreview_documentPage {
    margin-bottom: 20px;
}

.DocumentPreview_controls {
    display: flex;
}
