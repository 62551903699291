.Case__PinCode {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  label.only-mobile {
    display: none;
  }

  form {
    width: 100%;
    height: 56px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 18px;

    label {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      margin-top: -1px;
      font-weight: bold;
    }

    .PinCodeInputs {
      margin-left: -80px;
      input[type='number'] {
        outline: none;
        font-family: monospace;
        margin: 2px !important;
        font-size: 20px !important;
        font-weight: 600;
        width: 24px !important;
        background-color: transparent !important;
        color: 000 !important;
        border: none !important;
        padding-left: 0px !important;
        box-shadow: none !important;
      }
      input[type='number']::selection {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .PinCode__Incorrect {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .Case__PinCode {
    label.only-mobile {
      display: block;
      font-weight: bold;
    }
    form {
      margin-top: 20px;

      label {
        display: none;
      }

      .PinCodeInputs {
        margin-left: 0px;
      }
    }
    .PinCode__Incorrect {
      font-size: 15px;
    }
  }
}
