.InputPhone {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 10px;
    display: inline-flex;

    .PhoneInputInput {
        font-family: inherit;
        border-radius: none;
        border: none;
        height: 36px;
        font-size: 20px;
        line-height: 20px;
        padding: 6px 0 10px 0;
        outline: none;
        &::placeholder {
            color: #ccc
        }
    }
}