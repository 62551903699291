button, input[type="submit"], .btn {
  background-color: #F91E26 !important;
  color: white !important;
  border: none !important;
}
button:hover, input[type="submit"]:hover, .btn:hover {
  background-color: #F94046 !important;
  color: white !important;
}

.btn-group .btn.active {
  background-color: #f97175 !important;
}
.btn-out-window {
  position: absolute;
  left: -1000%;
}
