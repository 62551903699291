.ProfileForm {
  max-width: 800px;

  .legalFormStatus {
    display: flex;
    flex-direction: column;

    label {
      margin: 0;
      cursor: pointer;
      margin-bottom: -1px;
      border-radius: 0px;
    }
    input[type='radio'] {
      display: none;
    }
  }

  .bill {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
  }

  .submitWrap {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 480px) {
  .ProfileForm {
    .legalFormStatus {
      display: flex;
      flex-direction: row;
      label {
        margin: 0;
        border-radius: 0.25rem;
      }
    }
  }
}

.legalFormStatus  {
  .btn {
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    color: #979797 !important;
    &:hover {
      border-color: #F94046 !important;
    }
    &.active {
      background-color: #F94046 !important;
      color: #fff !important;
      border-color: #F94046 !important;
    }
  }
}