.Case__DataRow {
  margin-top: 20px;

  dt {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.3);
    font-weight: normal;
  }

  dd {
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }
}
