.DropZoneField {
  &__value {
    display: flex;
    p {
      color: #007bff;
      font-size: 0.7rem;
      padding-top: 2px;
      margin-bottom: 0px;
      margin-top: 2px;
    }
    button {
      margin: 0;
      padding: 0;
      border: 0;
      margin-left: 10px;
      margin-top: -1px;
    }
  }
  .btn-xs {
    padding: 0.05rem 0.5rem;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    cursor: pointer;
  }
}
