.RocketWork {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 140px;

  position: relative;

  .RocketWork__Bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    z-index: -1;
  }

  a,
  a:visited {
    text-decoration: underline;
    color: black;
  }

  header {
    background: rgba(0, 0, 0, 0.02);
    padding: 26px 40px;

    font-size: 20px;
    line-height: 20px;
    font-weight: bold;

    position: relative;
    z-index: 2;
  }

  .RocketWork__Inner {
    max-width: 568px; // 520 + 24 + 24
    width: 100%;
    margin: 0 auto;
    margin-top: 70px;

    padding: 0px 24px;

    .RocketWork__Case {
      width: 100%;

      .Case__Title {
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
      }

      .Case__Description {
        margin-top: 20px;
      }

      .Case__Partners {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px 0px;

        .Case__DataRow:first-child {
          margin-top: 0px;
        }
      }

      .Case__Signatures {
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
          &:first-child {
            p {
              margin-top: 0px;
            }
          }

          p {
            margin: 0px;
            margin-top: 20px;
          }
        }
      }

      .Case__SignatureForm {
        margin-top: 40px;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  body {
    // background-color: lightblue;

    .RocketWork {
      padding-bottom: 60px;
      header {
        text-align: center;
      }

      .RocketWork__Bg {
        display: none;
      }
      .RocketWork__Case {
        margin-top: 40px;
        width: calc(100% - 48px);
      }
    }
  }
}
