.Username {
  text-align: right;
  p {
    margin: 0;
  }
  .LegalName {
    margin-top: 5px;
    font-size: 10px;
  }
}
