.CaseNameEdit {
  display: flex;
  align-items: center;

  input {
    width: 100%;
  }

  p {
    margin: 0;
  }

  button {
    margin-left: 15px;
    border: 1px solid gray;
    width: 30px;
    height: 30px;
    background: #fff;
    border-color: #e5e5e5;
    transition: background 300ms;
    border-radius: 3px;
    &:hover {
      background: #f0f0f0;
    }
    svg {
      margin-top: -2px;
      margin-left: -1px;
      width: 16px;
      height: 16px;
    }
  }
}
