.Case__SignatureForm {
  .SignatureForm__Sign {
    width: 100%;
    height: 56px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    background: rgba(0, 0, 0, 0.04) !important;
    border-radius: 6px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: background 200ms;
    color: #000 !important;
    &:hover {
      background: rgba(0, 0, 0, 0.08) !important;
    }
  }
}
