.DocumentDetail {
  padding-bottom: 40px;
  a {
    font-size: 0.8em;
    text-decoration: underline;
    position: relative;
    top: -2px;
  }

  .print-only {
    display: none;
  }

  h2 {
    text-align: center;
    padding-bottom: 40px;
  }

  .EmptyState {
    color: #a0a0a0;
  }

  &__preview {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(56, 56, 89, 0.2);
    width: 180px;
  }
}

@media print {
  .content.container {
    margin-left: 0px;
  }

  .DocumentDetail {
    .print-only {
      display: block;
    }

    .no-print {
      display: none !important;
    }
  }
}
