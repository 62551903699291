.ProfileDetail {
  .Header {
    display: flex;
    justify-content: space-between;

    a {
      height: 39px;
      align-self: center;
    }
  }
}
